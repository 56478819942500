/**
 * Created by chris.sparshott on 23/10/13.
 */

watermark();
$('document').ready(function() {
  $(".carousel .viewport").easySlider(
    {
      controlsBefore: '<div class="controls">',
      controlsAfter: '</div>',
      numeric: false
    }
  );
});
